import PropTypes from 'prop-types'
import React from 'react'
import 'gatsby-theme-dish/global.css'
import Wrapper from './src/components/Wrapper'

// wrapRootElement is part of the Gatsby API, and thus cannot be the default export
export const wrapRootElement = ({ element }) => <Wrapper>{element}</Wrapper>

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
}
