/*
  BRAND LEVEL THEME
  If a project has multiple sites that have shared theming, those styles can be placed here.
  If a site needs to override a brand level style, that can be done by writing the desired override in the site level theme.js file.
*/
export default {
  colors: {
    primary: '#E10B3B',
    secondary: '#9D2235',
    tertiary: '#000010',
    dark: '#171725',
    light: '#f5f5f6',
    midgray: '#E8E8Ea',
    warning: '#FF7928',
    error: '#FF3068',
    success: '#2DE6BC',
    background1: '#FFEBEB',
    background2: '#E83D4F',
    background3: '#A70629',
    background4: '#700219',
    background5: '#C4C4C7',
    background6: '#525259',
  },
  gradients: {
    primary: 'linear-gradient(to right, #E10B3B #AF183A)',
    secondary: 'linear-gradient(to right, #9D2235, #00C9FF)',
    tertiary: 'linear-gradient(to right, #000090, #000010)',
  },
  alternateBgColor: true,
  logos: {
    chat: 'logo-chat.svg',
  },
  fonts: {
    headings: {
      family: '\'Montserrat\', sans-serif',
    },
    subheadings: {
      family: '\'Montserrat\', sans-serif',
    },
    body: {
      family: '\'Montserrat\', sans-serif',
    },
  },
  components: {
    Button: {
      rounded: 'min',
    },
    Column: {
      image: 'full',
      border: {
        stroke: {
          width: '2px',
        },
        topbar: {
          width: '10px',
        },
      },
    },
    PackageCard: {
      alignment: 'center', // left, center
    },
    Price: {
      dollarSign: {
        size: '18px',
        position: 'top', // top, bottom (default), center
        offsetY: '7px',
        weight: 'normal', // normal, bold
      },
      dollar: {
        weight: 'normal',
      },
      cents: {
        weight: 'normal',
      },
      duration: {
        position: 'top', // top, bottom (default), center, underCents, underPrice
        weight: 'normal',
        offsetY: '7px',
      },
    },
  },
  shadow: '0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1)',
  radius: '4px',
}
